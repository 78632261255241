<template lang="pug">
.brand-wrapper.om-wizard-similar-templates.pt-0
  .row
    .col-12.d-flex.justify-content-start
      wizard-title.text-left.mb-3.pb-3 {{ name }}
  .row
    .col-12.col-md-6.col-lg-4(v-for="template in templates" style="margin-bottom: 1.5rem")
      template-frame.cursor-pointer(
        @observable="addObservable($event.$el)"
        :allowSsr="isTemplatesWithSsr"
        @inited="updateDimensions"
        :dimensions="boxDimensions"
        :template="template"
        @contentLoaded="onContentLoaded"
        @click.native="showModal(template)"
        :themeKit="isSeasonalOffers ? undefined : getCorrectThemeKitForTemplate(template)"
      )
        template(#fallback)
          template-box.cursor-pointer(
            :key="template._id"
            :uniqueColors="queryColor ? template.universal : null"
            :color="getColor(template)"
            :template="template"
            @click.native="showModal(template)"
          )
  template-preview
  NewCampaignSMSPrompt
</template>

<script>
  import { get as _get } from 'lodash-es';
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';
  import GET_SIMILAR_TEMPLATES from '@/graphql/GetSimilarTemplates.gql';
  import TemplatePreview from '@/components/Modals/TemplatePreview';
  import TemplateBox from '@/components/Template/Themes/TemplateBox.vue';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';

  import sharedMixin from '../shared';
  const SEASONAL_USECASE_ID = '5fb7d3eee6535c0012b74d25';

  export default {
    components: {
      TemplateFrame,
      TemplateBox,
      TemplatePreview,
      NewCampaignSMSPrompt: () => import('@/components/Modals/NewCampaignSMSPrompt.vue'),
    },
    mixins: [sharedMixin, previewParentMixin, ssrMixin, observableCollectionMixin],

    data() {
      return {
        useCase: {},
        selectedColor: '',
        customTheme: null,
      };
    },

    computed: {
      name() {
        return _get(this.useCase, `name.${this.$i18n.locale}`);
      },

      queryColor() {
        return this.$route.query.color;
      },

      templates() {
        let templates = _get(this.useCase, 'templates', []);
        templates = templates.filter((template) => {
          if (!template.universal) return true;
          const themeKit = template?.template?.themeKit;
          return themeKit;
        });
        return templates;
      },
      themeKit() {
        return this.customTheme?.themeKit;
      },
      isSeasonalOffers() {
        return this.useCase?._id === SEASONAL_USECASE_ID;
      },
    },

    async created() {
      window.__skip_wizard_recommendation_loader = true;
      this.useCase = await this.fetchUseCase();
    },

    methods: {
      showModal(template) {
        const color = this.getColor(template) || undefined;
        const correctThemeKit = this.isSeasonalOffers
          ? undefined
          : this.getCorrectThemeKitForTemplate(template);
        this.$modal.show('template-preview', {
          templateId: template._id,
          color: correctThemeKit || this.isSeasonalOffers ? undefined : color,
          themeKit: correctThemeKit ?? undefined,
          isWizard: true,
          heapTracking: {
            name: 'template-recommendation-selection-wizard',
            data: {
              similarTemplates: true,
              templateName: template.name,
              theme: template.theme,
              useCaseName: this.useCase.name.en,
            },
          },
        });
      },
      getCorrectThemeKitForTemplate(template) {
        if (this.customTheme?.sourceTheme?.name === template.theme) {
          return this.themeKit;
        }
        return template?.template?.themeKit;
      },
      async fetchUseCase() {
        const {
          data: { useCase },
        } = await this.$apollo.query({
          query: GET_SIMILAR_TEMPLATES,
          variables: {
            id: this.$route.query.id,
          },
        });

        await this.setThemeKit();

        return useCase;
      },
      async setThemeKit() {
        this.customThemeLoading = true;
        const {
          data: { themes },
        } = await this.$apollo.query({
          query: GET_CUSTOM_THEMES,
        });
        this.customTheme = themes?.custom?.find(({ _id }) => _id === this.$route.query.customTheme);
      },
      getColor(template) {
        const themeColor = this.getCorrectThemeKitForTemplate(template)?.colors?.mainColor;
        return themeColor ?? this.queryColor;
      },
    },
  };
</script>

<style lang="sass">
  .om-wizard-similar-templates
    padding-bottom: 3.5rem

    .brand-themes-template-box
      background-size: cover !important
      border-radius: 6px
      width: 100%

    .om-wizard-title
      font-size: 2.75rem
      line-height: 2.75rem

    .template-thumbnail-box.fallback-shown
      padding: 0
      border: none
      .brand-themes-template-box
        width: 100%
        height: 100%
</style>
